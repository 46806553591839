import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Alert, Box } from '@mui/material';
import Link from '@mui/material/Link';
import HeaderBar from '../common/HeaderBar/HeaderBar';

function NotFoundPage() {
  return (
    <Box mt={5}>
      <HeaderBar name='lexisnexis' />

      <Link component={RouterLink} to='/' underline='always'>
        Go to Home Page
      </Link>
      <Alert sx={{ mt: 4 }} variant='outlined' severity='info'>
        Page Not Found | 404
      </Alert>
    </Box>
  );
}

export default NotFoundPage;
