import React from 'react';
import HeaderBar from '../common/HeaderBar/HeaderBar';
import VerifierPage from '../VerifierPage/VerifierPage';

const UTS = () => {
  return (
    <>
      <HeaderBar name='uts' />
      <VerifierPage id='uts' />
    </>
  );
};

export default UTS;
