import { useEffect, useState } from 'react';
import http from '../../services/http';

import { Link, Paper, Typography } from '@mui/material';
import { Route, useRouteMatch, useHistory, Link as RouterLink } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import * as storeActions from '../../redux/vcStore/vcActions';

import HeaderBar from '../common/HeaderBar/HeaderBar';
import SharedRoutes from '../common/SharedRoutes/SharedRoutes';
import ChooseIdPage from '../ChooseIdPage/ChooseIdPage';
import IdentityForm from '../common/Form/IdentityForm';
import PhotoPage from '../PhotoPage/PhotoPage';

const initialState = { error: '', loading: false, data: null, DDP: null };

const clientName = 'fpjourne';

const FPJourne = () => {
  const dispatch = useDispatch();
  const vcStore = useSelector(state => state.vcStore);

  const [VCRequest, setVCRequest] = useState({ ...initialState });

  const match = useRouteMatch();
  const history = useHistory();

  const submitFormInputs = values => {
    dispatch(storeActions.addFormInputs(values));
    history.push(`${match.url}/id`);
  };

  const submitRequest = async () => {
    try {
      setVCRequest(prev => ({ ...prev, loading: true, error: '', DDP: null }));
      // We will send only form values as we do not validate image for now
      const result = await http.post(`/api/issuer/issuance-request/${clientName}`, { ...vcStore.form });
      console.log('result homepage', result.data);
      setVCRequest(prev => ({ ...prev, loading: false, data: result.data }));
      history.replace(`/${clientName}/result`);
    } catch (error) {
      const axiosError = http.handleAxiosError(error);
      console.log('error', axiosError);
      setVCRequest(prev => ({
        ...prev,
        loading: false,
        error: axiosError.message,
        DDP: axiosError.data?.DDP,
      }));
      history.push(`/${clientName}/error`);
    }
  };

  const resetState = () => {
    dispatch(storeActions.resetState());
    setVCRequest({ ...initialState });
  };

  useEffect(() => {
    return () => resetState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HeaderBar
        name={clientName}
        logoSize={{ maxWidth: '240px', maxHeight: '100px' }}
        style={{
          py: 1,
          px: 3,
          my: 3,
          height: 'max-content',
          justifyContent: 'space-between',
          backgroundImage: theme => theme.gradient,
        }}
        extra={
          <Typography
            variant='h5'
            component='h1'
            sx={{
              letterSpacing: '2px',
              transform: 'translateY(24px)',
              color: theme => theme.header?.logoColor,
            }}
          >
            Journe Society
          </Typography>
        }
      />
      <Paper
        elevation={3}
        sx={{ px: 3, py: 4, minHeight: '470px', backgroundImage: theme => theme.gradient }}
      >
        <SharedRoutes id={clientName} VCRequest={VCRequest} path={match.path} resetState={resetState}>
          <Route path={`${match.path}/id`}>
            <ChooseIdPage addFormInputs={storeActions.addFormInputs} />
          </Route>

          <Route path={`${match.path}/:id/:photo`}>
            <PhotoPage
              client={clientName}
              actions={storeActions}
              loading={VCRequest.loading}
              submitRequest={submitRequest}
            />
          </Route>

          <Route path={match.path}>
            <IdentityForm handleSubmit={submitFormInputs} client={clientName} />
            <Link component={RouterLink} to='/fpjourne/verifier' underline='always'>
              Go to verifier
            </Link>
          </Route>
        </SharedRoutes>
      </Paper>
    </>
  );
};

export default FPJourne;
