import { grey } from '@mui/material/colors';
import FPJOURNE_LOGO from './logos/fpjs.png';
import PCB_LOGO from './logos/pcb_logo.png';
import SYNCHRONY_LOGO from './logos/synchrony_logo.png';
import LEXISNEXIS_LOGO from './logos/lexisnexis_logo.png';
import UTS_LOGO from './logos/uts_logo.png';
import ONEVET_LOGO from './logos/onevet_logo.png';
import ACME_LOGO from './logos/acme_logo.png';
import BEST_BUY_LOGO from './logos/best_buy.png';
//!! You can also pass url (ex. https://vclogos.blob.core.windows.net/logos/cn.png) in as a value to logo key

const themes = {
  fpjourne: {
    header: {
      bgColor: '',
      logo: FPJOURNE_LOGO,
      logoColor: '#F9C040',
    },
    backgroundImage:
      'url(https://www.fpjourne.com/themes/custom/fpjournetheme/medias/history/bg-content-specs.jpg)',
    gradient: 'linear-gradient(120deg, #002E41, #1d3e4b, #373737)',
    palette: {
      mode: 'dark',
      primary: {
        main: '#cea392',
      },
      secondary: {
        main: '#fff',
      },
      background: {
        default: '#002E41',
        paper: 'rgba(0,0,0,0.80)',
      },
      text: {
        primary: grey[100],
        secondary: grey[500],
      },
    },
  },
  bestbuy: {
    gradient: 'linear-gradient(to right, #0046bf, #0060cd, #1178d9, #368fe2, #58a6e9)',

    header: {
      bgColor: '#0046bf',
      logo: BEST_BUY_LOGO,
    },
  },
  pcb: {
    header: {
      // logo: PCB_LOGO,
      logo: SYNCHRONY_LOGO,
      // bgColor: '#0575ba',// pcb bg color
      bgColor: '#00364f',  // Synchrony bg color
      // backgroundImage: 'url(https://www.mypcb.com/assets/files/44a1PENM/Downtown%20bank%20night.jpg)',
      // headerImage: 'url(https://www.mypcb.com/assets/files/44a1PENM/Downtown%20bank%20night.jpg)',
    },
  },
  onevet: {
    header: {
      logo: ONEVET_LOGO,
      bgColor: '#0575ba',
    },
  },
  uts: {
    header: {
      logo: UTS_LOGO,
      bgColor: '#0c529c',
    },
  },
  acme: {
    header: {
      logo: ACME_LOGO,
      bgColor: '#2c387e',
    },
  },
  default: {
    header: {
      logo: LEXISNEXIS_LOGO,
      bgColor: '#C8C9C7',
    },
    palette: {
      mode: 'light',
      primary: {
        main: '#1976D2',
      },
      secondary: {
        main: '#9c27b0',
      },
      background: {
        default: '#fff',
        paper: '#fff',
      },
    },
  },
};

export default themes;
