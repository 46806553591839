import { Box, Link, Typography } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import HeaderBar from '../common/HeaderBar/HeaderBar';

const HomePage = () => {
  const links = [
    { to: '/bestbuy', label: 'Go to Best Buy' },
    { to: '/acme', label: 'Go to ACME (identity verification)' },
    { to: '/lexisnexis', label: 'Go to LexisNexis (business verification)' },
    // { to: '/pcb', label: 'Go to Phelps County Bank' },
    { to: '/pcb', label: 'Go to Synchrony Bank' },
    { to: '/fpjourne', label: 'Go to F.P. Journe' },
    { to: '/onevet', label: 'Go to OneVet' },
    { to: '/verifiersoptions', label: 'Go to credentials verifier', color: 'secondary' },
  ];

  return (
    <Box mt={5}>
      <HeaderBar name='lexisnexis' />

      <Typography variant='h4'>Welcome to LexisNexis Verifiable Credentials</Typography>
      <Typography variant='subtitle1'>Please select from the following options:</Typography>

      {links.map(({ label, ...rest }) => {
        return (
          <Link {...rest} key={label} sx={{ display: 'block' }} component={RouterLink} underline='always'>
            {label}
          </Link>
        );
      })}
    </Box>
  );
};

export default HomePage;
