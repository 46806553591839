import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import scanGIF from '../../utils/AcuantImages/scan_process.gif';
import selfiePNG from '../../utils/AcuantImages/illustration2@3x.png';

import { Box, Button, Stack, Typography } from '@mui/material';
import Processing from '../common/Processing/Processing';
import { LoadingButton } from '@mui/lab';
import LoadingOverlay from '../common/LoadingOverlay/LoadingOverlay';
import Notification from '../common/Notification/Notification';

const PhotoPage = ({ actions, submitRequest, loading }) => {
  let { id, photo } = useParams();
  const history = useHistory();

  const vcStore = useSelector(state => state.vcStore);
  const dispatch = useDispatch();

  const [camera, setCamera] = useState({ open: false, isIOS: false });

  useEffect(() => {
    const isIOS = window.AcuantCamera?.isIOS();

    if (isIOS === undefined) {
      const root = history.location.pathname.replace(`/${id}/${photo}`, '');
      history.replace(root);
    }

    setCamera(prev => ({ ...prev, isIOS }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const proceedToNextStep = async () => {
    const options = {
      passport: {
        photoFront: () => history.push('photoSelfie'),
        photoSelfie: submitRequest,
      },
      dl: {
        photoFront: () => history.push('photoBack'),
        photoBack: () => history.push('photoSelfie'),
        photoSelfie: submitRequest,
      },
    };

    const action = options[id][photo];

    return await action();
  };

  const backCamera = () => {
    const cameraConfig = {
      success: {
        onCaptured: () => {
          setCamera(prev => ({ ...prev, open: false }));
          dispatch(actions.proccessingStart());
        },
        onFrameAvailable: () => {}, //do nothing,
        onCropped: async response => {
          await new Promise(r => setTimeout(r, 1500)); // sleep function to simulate async call for processing
          dispatch(actions.proccessingFinish());
          if (!response) {
            const document = id === 'dl' ? "Driver's License" : 'Passport';
            dispatch(actions.photoError({ error: `Could not recognize ${document}, please try again` }));
            return startCamera(); // no response, try again;
          }
          if (photo === 'photoFront') dispatch(actions.setPhotoFront({ image: response.image.data }));
          if (photo === 'photoBack') dispatch(actions.setPhotoBack({ image: response.image.data }));
        },
      },
      error: {
        onError: (description, code) => {
          const options = {
            'start-fail': 'This device does not support Live Capture. Launch manual capture.',
            'repeat-fail': 'Repeat failed. Please try again',
            'sequence-break': 'Live Capture failed. Please try again',
            default: 'This device does not support Live Capture. Launch manual capture.',
          };
          console.log('-ERROR-----------------------------------------');
          console.dir({ description, code, fromOptions: options[code] || options['default'] });
          setCamera(prev => ({ ...prev, open: false }));
        },
      },
      options: {
        text: {
          NONE: 'ALIGN',
          SMALL_DOCUMENT: 'MOVE CLOSER',
          GOOD_DOCUMENT: null, //null countdown
          BIG_DOCUMENT: 'TOO CLOSE',
          CAPTURING: 'CAPTURING',
          TAP_TO_CAPTURE: 'TAP TO CAPTURE',
        },
      },
    };

    if (window.AcuantCamera?.isCameraSupported) {
      window.AcuantCameraUI.start(cameraConfig.success, cameraConfig.error.onError, cameraConfig.options);
    } else {
      window.AcuantCamera.startManualCapture(cameraConfig.success, cameraConfig.error.onError);
    }
  };

  const frontCamera = () => {
    const cameraConfig = {
      handlers: {
        onDetectorInitialized: () => {},
        onDetection: text => {},
        onOpened: () => {},
        onClosed: () => setCamera(prev => ({ ...prev, open: false })), // if user closed without taking photo we will need to hide overlay
        onError: error => {},
        onPhotoTaken: () => {},
        onPhotoRetake: () => {},
        onCaptured: async image => {
          setCamera(prev => ({ ...prev, open: false }));
          dispatch(actions.proccessingStart());
          await new Promise(r => setTimeout(r, 1500)); // sleep function to simulate async call for processing
          dispatch(actions.proccessingFinish());
          if (!image) return startCamera(); // start again if image is not found
          dispatch(actions.setPhotoSelfie({ image: 'data:image/jpeg;base64,' + image }));
        },
      },
      options: {
        FACE_NOT_FOUND: 'FACE NOT FOUND',
        TOO_MANY_FACES: 'TOO MANY FACES',
        FACE_ANGLE_TOO_LARGE: 'FACE ANGLE TOO LARGE',
        PROBABILITY_TOO_SMALL: 'PROBABILITY TOO SMALL',
        FACE_TOO_SMALL: 'FACE TOO SMALL',
        FACE_CLOSE_TO_BORDER: 'TOO CLOSE TO THE FRAME',
      },
    };

    window.AcuantPassiveLiveness.start(cameraConfig.handlers, cameraConfig.options);
  };

  const getImageSrc = () => {
    if (photo === 'photoSelfie' && !vcStore[photo]) return selfiePNG;
    return vcStore[photo] || scanGIF;
  };

  const getHeading = () => {
    const options = {
      passport: {
        photoFront: 'Capture passport portrait page',
        photoSelfie: 'Capture a selfie',
      },
      dl: {
        photoSelfie: 'Capture a selfie',
        photoFront: "Capture front of Driver's License",
        photoBack: "Capture back of Driver's License",
      },
    };

    return options[id][photo];
  };

  const startCamera = (options = { skip: false }) => {
    const { skip } = options;
    if (skip) {
      dispatch(actions.setPhotoSelfie({ image: selfiePNG }));
    } else {
      openCamera().then(() => {
        photo === 'photoSelfie' ? frontCamera() : backCamera();
      });
    }
  };
  // We need to make sure that camera set to open before start Acuant, it will mount overlay for IOS and start camera after
  const openCamera = async () => setCamera(prev => ({ ...prev, open: true }));

  const retry = () => startCamera();

  if (!vcStore) {
    return (
      <Notification
        severity='warning'
        showNotification={true}
        notificationText='Failed to find client state in store'
      />
    );
  }

  return (
    <>
      {vcStore.proccessing ? (
        <Processing size={100} />
      ) : (
        <>
          <Notification
            severity='warning'
            notificationText={vcStore.error}
            showNotification={!!vcStore.error}
          />

          <Typography variant='h6' align='center' gutterBottom color='text.primary'>
            {getHeading()}
          </Typography>
          <Box
            component='img'
            alt='idscango'
            sx={{ maxHeight: '300px', maxWidth: '100%', display: 'block', mx: 'auto' }}
            src={getImageSrc()}
            boxShadow={vcStore[photo] ? 4 : 2}
            border='1px solid'
            borderColor={theme => theme.palette.primary.main}
            borderRadius={1}
          />

          <Box my={2} display='flex' justifyContent={'center'}>
            {!vcStore[photo] ? (
              <>
                <Button variant='outlined' onClick={() => startCamera()}>
                  Open camera
                </Button>
                {photo === 'photoSelfie' ? (
                  <Button variant='text' sx={{ ml: 3 }} onClick={() => startCamera({ skip: true })}>
                    Skip this step
                  </Button>
                ) : null}
              </>
            ) : (
              <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }}>
                <LoadingButton
                  color='primary'
                  variant='outlined'
                  loading={loading}
                  onClick={proceedToNextStep}
                >
                  {photo === 'photoSelfie' ? 'Submit Request' : 'Continue with this image'}
                </LoadingButton>

                <Button variant='outlined' color='secondary' onClick={retry}>
                  Retry
                </Button>

                <Button variant='text' onClick={() => history.goBack()}>
                  Return to previous step
                </Button>
              </Stack>
            )}
          </Box>
          <LoadingOverlay loading={loading} />
        </>
      )}

      <Camera camera={camera} />
    </>
  );
};

export default PhotoPage;

const Camera = ({ camera }) => {
  // For IOS devices we need to have an overlay and center the camera image
  if (camera.isIOS && camera.open) {
    return (
      <Box
        sx={{
          position: 'fixed',
          bgcolor: '#000',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div id='acuant-face-capture-container' className='face-camera-container'>
          <div id='face-detection-text' className='detection-state'></div>
        </div>
        <div id='acuant-camera'></div>
      </Box>
    );
  }

  return (
    <>
      <div id='acuant-face-capture-container' className='face-camera-container'>
        <div id='face-detection-text' className='detection-state'></div>
      </div>
      <div id='acuant-camera'></div>
    </>
  );
};
