import React, { useState } from 'react';
import { DialogContent, DialogActions, DialogTitle, Dialog, Button, Alert } from '@mui/material';
import './styles.module.css';

function RequestSummary({ show, summary, title }) {
  const [expand, setExpand] = useState(false);

  if (!show || !summary) return null;

  return (
    <>
      <Alert
        sx={{ my: 3 }}
        severity='info'
        variant='outlined'
        action={
          <Button color='inherit' size='small' onClick={() => setExpand(!expand)}>
            Show results
          </Button>
        }
      >
        {title}
      </Alert>

      <Dialog open={expand} onClose={() => setExpand(!expand)} fullWidth maxWidth='xl' scroll='paper'>
        <DialogTitle> {title}</DialogTitle>
        <DialogContent dividers className='dialog'>
          <pre>{JSON.stringify(summary, null, 2)}</pre>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </>
  );
}

export default RequestSummary;
