import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import ReactCanvasConfetti from 'react-canvas-confetti';

const ConfettiContext = createContext();

export function ConfettiProvider({ children }) {
  const [fire, setFire] = useState(false);

  const shootConfetti = useCallback(() => setFire(true), []);

  useEffect(() => {
    // reset confetti, it will shoot only when boolean turned to truthy value
    if (fire) setFire(false);
  }, [fire]);

  const confettiSettings = {
    fire,
    resize: true,
    spread: 70,
    particleCount: 250,
    origin: { y: 0.6 },
  };

  const style = {
    top: '0px',
    left: '0px',
    zIndex: 100,
    width: '100%',
    position: 'fixed',
    pointerEvents: 'none',
  };

  return (
    <ConfettiContext.Provider value={{ shootConfetti }}>
      {children}
      <ReactCanvasConfetti {...confettiSettings} style={style} />
    </ConfettiContext.Provider>
  );
}

export function useConfetti() {
  const { shootConfetti } = useContext(ConfettiContext);
  return shootConfetti;
}
