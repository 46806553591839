import React, { useEffect, useState } from 'react';

import QRcode from 'qrcode.react';
import { Box } from '@mui/system';

const black = '#000'; // black color
const white = '#f7f7f7'; // white color

function QRCode({ value, costumerId }) {
  const [color, setColor] = useState(black);

  // authenticator fails to read white qrCode on android, we have a doubleClick event listener on a component in order to change color

  const changeColor = () => setColor(prev => (prev === black ? white : black));

  const isAndroid = () => {
    const ua = navigator?.userAgent;
    if (ua) return /android/i.test(ua);
    return false;
  };

  useEffect(() => {
    if (costumerId === 'fpjourne' && !isAndroid()) {
      //'#64b5f6' ; blue color
      setColor(white);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!value) return null;

  return (
    <a href={value} onDoubleClick={changeColor}>
      <Box
        component={QRcode}
        bgColor={'transparent'}
        fgColor={color}
        value={value}
        sx={{ p: 3, my: 1 }}
        renderAs='svg'
        display='block'
        margin='0 auto'
        width='100%'
        height='100%'
        maxWidth='400px'
        maxHeight='auto'
      />
    </a>
  );
}

export default QRCode;
