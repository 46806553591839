/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button } from '@mui/material';
import React, { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { statesList } from '../../../utils/statesList';

import Select from '../Select/Select';
import TextField from '../TextField/TextField';
import FormActions from '../FormActions/FormActions';
import Maskfield from '../TextField/MaskedTextField';

const initialFields = {
  firstName: '',
  middleInitials: '',
  lastName: '',
  address: '',
  city: '',
  state: '',
  zipCode: '',
  email: '',
  phoneNumber: '',
};

const validation = Yup.object().shape({
  firstName: Yup.string()
    .matches(/^[A-Za-z ]*$/, 'The last name should contain only letters')
    .required('Please provide your first name.'),
  middleInitials: Yup.string().matches(/^[A-Za-z ]*$/, 'The middle name should contain only letters'),
  lastName: Yup.string()
    .matches(/^[A-Za-z ]*$/, 'The last name should contain only letters')
    .required('Please provide your last name.'),
  address: Yup.string().required('Please provide your address.'),
  city: Yup.string().required('Please provide a city.'),
  state: Yup.string().required('Please provide a state.'),
  zipCode: Yup.string()
    .matches(/^[0-9]+$/, 'Must be digits only')
    .min(5, 'Minimum 5 digits')
    .max(5, 'Maximum 5 digits')
    .required('Please enter a valid zipcode.'),
  email: Yup.string().email('Invalid email.').required('Please provide your email.'),
  phoneNumber: Yup.string()
    .matches(/^[0-9]+$/, 'Please provide a valid phone number.')
    .min(10, 'Phone number must be at least 10 digits.')
    .max(10, 'Phone number must be no more than 10 digits.')
    .required('Please provide your phone number.'),
});

const IdentityForm = ({ handleSubmit, client }) => {
  const form = useRef(null);

  const formState = useSelector(state => state.vcStore.form);

  useEffect(() => {
    if (formState?.firstName) {
      form.current.setValues(formState);
    }
  }, []);

  return (
    <Formik
      innerRef={form}
      initialValues={initialFields}
      validationSchema={validation}
      onSubmit={handleSubmit}
    >
      {context => {
        return (
          <Form noValidate autoComplete='off'>
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <TextField size='small' name='firstName' label='First Name ' required />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField size='small' name='middleInitials' label='Middle Initial' />
              </Grid>
              <Grid item xs={12} md={5}>
                <TextField size='small' name='lastName' label='Last Name ' required />
              </Grid>

              <Grid item xs={12} md={8}>
                <TextField size='small' name='address' label='Address ' required />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField size='small' name='city' label='City ' required />
              </Grid>
              <Grid item xs={12} md={8}>
                <Select size='small' name='state' label='State ' required options={statesList} />
              </Grid>
              <Grid item xs={12} md={4}>
                <Maskfield
                  size='small'
                  mask='#0000'
                  name='zipCode'
                  label='Zip Code *'
                  definitions={{ '#': /[1-9]/ }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField size='small' type='email' name='email' label='E-mail Address ' required />
              </Grid>
              <Grid item xs={12}>
                <Maskfield
                  type='tel'
                  size='small'
                  name='phoneNumber'
                  mask='(#00) 000-0000'
                  label='Telephone Number *'
                  definitions={{ '#': /[1-9]/ }}
                />
              </Grid>
            </Grid>
            <Box display='flex' justifyContent={{ sm: 'flex-end', xs: 'center' }} width={'100%'} my={3}>
              <Button type='submit' variant='outlined' color='primary'>
                Continue
              </Button>
            </Box>
            {/* FormActions is for demo, it has buttons for pre populating input fields */}
            <FormActions context={context} formType='identity' client={client} />
          </Form>
        );
      }}
    </Formik>
  );
};

export default IdentityForm;
