import { Box, Button, Typography } from '@mui/material';
import React, { useState } from 'react';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

const ChooseIdPage = ({ addFormInputs }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [id, setId] = useState({ value: '', error: '' });

  const handleChange = event => setId({ error: '', value: event.target.value });

  const handleContinue = () => {
    if (!id.value) return setId(prev => ({ ...prev, error: 'Please select one of the options' }));
    dispatch(addFormInputs({ id: id.value }));
    history.push(`${id.value}/photoFront`);
  };

  return (
    <Box>
      <Typography variant='h4' color='text.primary' gutterBottom>
        Verify your Identification
      </Typography>

      <FormControl size='medium' error={!!id.error}>
        <FormLabel id='id-verification' required>
          Please select from one of the options below. Biometric face verification will be required
        </FormLabel>

        <RadioGroup
          aria-labelledby='id-verification'
          name='id-verification'
          value={id.value}
          onChange={handleChange}
        >
          <FormControlLabel
            value='passport'
            control={<Radio />}
            color='text.primary'
            label={
              <Typography color='text.primary'>A Valid International Travel Passport Document</Typography>
            }
          />
          <FormControlLabel
            value='dl'
            control={<Radio />}
            color='text.primary'
            label={<Typography color='text.primary'>A Valid Driver License</Typography>}
          />
        </RadioGroup>
        {/* <FormHelperText>{id.error}</FormHelperText> */}
      </FormControl>

      <Box display='flex' justifyContent={{ sm: 'flex-end', xs: 'center' }} width={'100%'} my={3}>
        <Button onClick={() => history.goBack()} variant='text' color='secondary' sx={{ mr: 4 }}>
          Go back
        </Button>
        <Button onClick={handleContinue} variant='outlined' color='primary'>
          Continue
        </Button>
      </Box>
    </Box>
  );
};

export default ChooseIdPage;
