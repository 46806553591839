import React, { useEffect, useState } from 'react';
import http from '../../services/http';
import { Link as RouterLink } from 'react-router-dom';
import { Typography, Link } from '@mui/material';

import Notification from '../common/Notification/Notification';
import QRCode from '../common/QRCode/QRCode';

import RequestSummary from '../common/RequestSummary/RequestSummary';
import { useConfetti } from '../../context/ConfettiContext';

const ResultPage = ({ id, data, resetState }) => {
  const [scanned, setScanned] = useState({
    request_retrieved: false,
    issuance_successful: false,
    issuance_failed: false,
    axiosError: false,
    message: '',
  });

  const [showNotification, setShowNotification] = useState(false);

  const shootConfetti = useConfetti();

  useEffect(() => {
    setShowNotification(true); // will trigger animation on notification block

    const checkStatus = setInterval(async () => {
      // start polling our backend for data that MS gonna drop.
      try {
        const { data: response } = await http.get('/api/issuer/issuance-response?id=' + data.id);
        if (response.status) {
          console.log(`response.status`, response.status);

          if (response.status === 'request_retrieved') {
            setScanned(prev => ({ ...prev, request_retrieved: true, message: response.message }));
          }
          if (response.status === 'issuance_successful') {
            setScanned(prev => ({ ...prev, issuance_successful: true, message: response.message }));
            shootConfetti();
            clearInterval(checkStatus);
          }
          if (response.status === 'issuance_failed' || response.status === 'issuance_error') {
            setScanned(prev => ({ ...prev, issuance_failed: true, message: response.message }));
            clearInterval(checkStatus);
          }
        }
      } catch (error) {
        const axiosError = http.handleAxiosError(error);
        console.log(`axiosError`, axiosError);
        setScanned(prev => ({ ...prev, axiosError: true, message: axiosError.message }));
        clearInterval(checkStatus);
      }
    }, 3000);

    return () => {
      clearInterval(checkStatus);
      resetState();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getNotificationText = () => scanned.message || 'Success! Scan the QR Code with your device camera.';
  const error = scanned.issuance_failed || scanned.axiosError;

  return (
    <>
      <Notification
        showNotification={showNotification}
        severity={error ? 'error' : 'success'}
        notificationText={getNotificationText()}
      />
      <Link component={RouterLink} to={`/${id}`} replace underline='always' onClick={resetState}>
        Go to Home Page
      </Link>

      {scanned.request_retrieved ? (
        <PinCode pin={data.pin} hide={scanned.issuance_successful} />
      ) : (
        <QRCode value={data.url} costumerId={id} />
      )}

      {/* // TODO: DDP FOR DEMO, REMOVE IT LATER */}
      <RequestSummary summary={data?.DDP} title='DDP Response' show={scanned.issuance_successful} />
    </>
  );
};

export default ResultPage;

const PinCode = ({ pin, hide }) =>
  hide ? null : (
    <Typography variant='h4' color='text.primary'>
      Pin : {pin}
    </Typography>
  );
