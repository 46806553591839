import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ErrorPage from '../../ErrorPage/ErrorPage';
import ResultPage from '../../ResultPage/ResultPage';
import VerifierPage from '../../VerifierPage/VerifierPage';

/*
This component combines routing to common pages, if your flow requires more routes you can pass them as children
*/
const SharedRoutes = ({ id, path, VCRequest, resetState = () => {}, children }) => {
  return (
    <Switch>
      <Route path={`${path}/error`}>
        <ErrorPage id={id} error={VCRequest.error} DDP={VCRequest.DDP} resetState={resetState} />
      </Route>
      <Route path={`${path}/verifier`}>
        <VerifierPage id={id} />
      </Route>
      <Route path={`${path}/result`}>
        <ResultPage id={id} data={VCRequest.data} resetState={resetState} />
      </Route>
      {children}
    </Switch>
  );
};

export default SharedRoutes;
