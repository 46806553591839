import {
  SET_PHOTO_FRONT,
  SET_PHOTO_BACK,
  SET_PHOTO_SELFIE,
  PHOTO_ERROR,
  PHOTO_PROCESSING_FINISH,
  PHOTO_PROCESSING_START,
  ADD_FORM_INPUTS,
  RESET_STATE,
} from './vcReducer';

export const proccessingStart = () => ({
  type: PHOTO_PROCESSING_START,
});

export const proccessingFinish = () => ({
  type: PHOTO_PROCESSING_FINISH,
});

export const setPhotoFront = ({ image }) => ({
  type: SET_PHOTO_FRONT,
  payload: image,
});

export const setPhotoBack = ({ image }) => ({
  type: SET_PHOTO_BACK,
  payload: image,
});

export const setPhotoSelfie = ({ image }) => ({
  type: SET_PHOTO_SELFIE,
  payload: image,
});

export const photoError = ({ error }) => ({
  type: PHOTO_ERROR,
  payload: error,
});

export const resetState = () => ({
  type: RESET_STATE,
});

export const addFormInputs = payload => ({
  type: ADD_FORM_INPUTS,
  payload,
});
