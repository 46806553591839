import React from 'react';
import http from '../../services/http';

import { useState } from 'react';

import { Link } from '@mui/material';
import { Route, useRouteMatch, useHistory, Link as RouterLink } from 'react-router-dom';

import HeaderBar from '../common/HeaderBar/HeaderBar';

import SharedRoutes from '../common/SharedRoutes/SharedRoutes';
import BusinessRepForm from '../common/Form/BusinessRepForm';

const initialState = { error: '', loading: false, data: null, DDP: null };

const clientName = 'lexisnexis';

function LexisNexis() {
  const [VCRequest, setVCRequest] = useState({ ...initialState });

  const match = useRouteMatch();
  const history = useHistory();

  const formatDate = date => {
    let formatted = date.getFullYear();
    formatted += date.getMonth() > 10 ? date.getMonth() + '' : '0' + date.getMonth();
    formatted += date.getDate() > 10 ? date.getDate() + '' : '0' + date.getMonth();
    return formatted; //returns YYYYMMDD ex. 19900930 needed for DDP
  };

  const handleSubmit = async (values, actions) => {
    try {
      // !! values object can be different for different forms, LexisNexis collects data in nested objects {business:{...}, representative:{...}}
      values.representative.dateOfBirth = formatDate(values.representative.dateOfBirth);

      setVCRequest(prev => ({ ...prev, loading: true, error: '', DDP: null }));

      // this flow will share routes with onevet flow
      const result = await http.post(`/api/issuer/issuance-request/${clientName}`, values);
      console.log('result homepage', result.data);
      setVCRequest(prev => ({ ...prev, loading: false, data: result.data }));

      history.replace(`/${clientName}/result`);
    } catch (error) {
      const axiosError = http.handleAxiosError(error);
      console.log('error', axiosError);

      setVCRequest(prev => ({
        ...prev,
        loading: false,
        error: axiosError.message,
        DDP: axiosError.data?.DDP,
      }));

      history.push(`/${clientName}/error`);
    }
    actions.resetForm();
  };

  return (
    <>
      <HeaderBar name={clientName} />

      <SharedRoutes id={clientName} VCRequest={VCRequest} path={match.path}>
        <Route path={match.path}>
          <BusinessRepForm handleSubmit={handleSubmit} loading={VCRequest.loading} client={clientName} />
          <Link component={RouterLink} to={`/${clientName}/verifier`} underline='always'>
            Go to verifier
          </Link>
        </Route>
      </SharedRoutes>
    </>
  );
}

export default LexisNexis;
