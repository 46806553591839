import React from 'react';
import TextField from '@mui/material/TextField';
import { DatePicker as MUIDatePicker } from '@mui/lab';
import { useField } from 'formik';
import LocalizationAdapter from '../LocalizationAdapter/LocalizationAdapter';

const DatePicker = ({ name, size = 'medium', ...otherProps }) => {
  const [field, meta] = useField(name);

  const configDateTimePicker = {
    ...field,
    ...otherProps,
    error: false,
  };

  if (meta && meta.touched && meta.error) {
    configDateTimePicker.error = true;
    configDateTimePicker.helperText = meta.error;
  }

  return (
    <LocalizationAdapter>
      <MUIDatePicker
        disableFuture
        {...configDateTimePicker}
        onChange={value => configDateTimePicker.onChange({ target: { name, value } })}
        renderInput={params => {
          params.error = configDateTimePicker.error;
          return (
            <TextField
              name={name}
              size={size}
              onBlur={configDateTimePicker.onBlur}
              helperText={configDateTimePicker.helperText || null}
              fullWidth
              {...params}
            />
          );
        }}
      />
    </LocalizationAdapter>
  );
};

export default DatePicker;
