import React from 'react';
import { useField } from 'formik';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

const SelectWrapper = ({ name, options, ...otherProps }) => {
  const [field, meta] = useField(name);

  const configSelect = {
    ...field,
    ...otherProps,
    fullWidth: true,
  };

  if (meta && meta.touched && meta.error) {
    configSelect.error = true;
    configSelect.helperText = meta.error;
  }

  return (
    <TextField
      {...configSelect}
      select
      SelectProps={{
        MenuProps: {
          PaperProps: {
            style: {
              maxHeight: '300px',
            },
          },
        },
      }}
    >
      {Object.entries(options).map(([key, value], index) => {
        return (
          <MenuItem key={index} value={key}>
            {value}
          </MenuItem>
        );
      })}
    </TextField>
  );
};

export default SelectWrapper;
