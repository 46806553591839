import { Box, Link, Typography, useTheme } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import allThemes from '../Layout/themes';

const HeaderBar = ({ name = 'lexisnexis', extra, style = {}, logoSize = null, className = '' }) => {
  const defaultHeader = allThemes.default.header;
  const theme = useTheme();
  const backgroundColor = theme.header?.bgColor || defaultHeader.bgColor;
  const logo = theme.header?.logo || defaultHeader.logo;

  return (
    <Box
      className={className}
      boxShadow={3}
      borderRadius={1}
      sx={{
        px: 2,
        py: 1,
        my: 4,
        height: '70px',
        display: 'flex',
        backgroundColor,
        alignItems: 'center',
        position: 'relative',
        ...style,
      }}
    >
      <Link component={RouterLink} to={`/${name === 'lexisnexis' ? '' : name}`}>
        <Box
          src={logo}
          alt='logo'
          component='img'
          sx={logoSize || { maxWidth: '140px', maxHeight: '100%' }}
        />
      </Link>
      {extra || null}
      <Typography
        component='h2'
        variant='caption'
        color='text.primary'
        sx={{ position: 'absolute', bottom: 0, right: 0, transform: 'translateY(24px)' }}
      >
        Powered by LexisNexis
      </Typography>
    </Box>
  );
};

export default HeaderBar;
