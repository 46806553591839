import { Alert, AlertTitle, Collapse } from '@mui/material';
import React from 'react';

function Notification({ showNotification, severity, notificationText }) {
  return (
    <Collapse in={showNotification}>
      <Alert severity={severity} sx={{ mb: 2 }}>
        {severity === 'error' && <AlertTitle>Check your inputs and try again</AlertTitle>}
        {notificationText}
      </Alert>
    </Collapse>
  );
}

export default Notification;
