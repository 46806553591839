import { Backdrop, CircularProgress } from '@mui/material';
import React from 'react';

const LoadingOverlay = ({ loading }) => {
  return (
    <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
      <CircularProgress color='inherit' sx={{ mt: '-40px' }} />
      {/* <Typography variant='h5' sx={{ ml: 1 }}>
      Submitting...
    </Typography> */}
    </Backdrop>
  );
};

export default LoadingOverlay;
