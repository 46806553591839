export const PHOTO_ERROR = 'PHOTO_ERROR';
export const SET_PHOTO_FRONT = 'SET_PHOTO_FRONT';
export const SET_PHOTO_BACK = 'SET_PHOTO_BACK';
export const SET_PHOTO_SELFIE = 'SET_PHOTO_SELFIE';
export const PHOTO_PROCESSING_START = 'PHOTO_PROCESSING_START';
export const PHOTO_PROCESSING_FINISH = 'PHOTO_PROCESSING_FINISH';
export const RESET_STATE = 'RESET_STATE';
export const ADD_FORM_INPUTS = 'ADD_FORM_INPUTS';

const initialState = {
  proccessing: false,
  photoFront: '',
  photoBack: '',
  photoSelfie: '',
  error: '',
  form: {
    // it can be different form if we have more fields
    id: '',
    firstName: '',
    middleInitials: '',
    lastName: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    email: '',
    phoneNumber: '',
  },
};

const vcReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case PHOTO_PROCESSING_START:
      return { ...state, proccessing: true, error: '' };
    case PHOTO_PROCESSING_FINISH:
      return { ...state, proccessing: false };
    case SET_PHOTO_FRONT:
      return { ...state, photoFront: payload, error: '' };
    case SET_PHOTO_BACK:
      return { ...state, photoBack: payload, error: '' };
    case SET_PHOTO_SELFIE:
      return { ...state, photoSelfie: payload, error: '' };
    case PHOTO_ERROR:
      return { ...state, error: payload };
    case ADD_FORM_INPUTS:
      return { ...state, form: { ...state.form, ...payload } };
    case RESET_STATE:
      return { ...initialState };
    default:
      return state;
  }
};

export default vcReducer;
