import React, { useState } from 'react';
import { Button, Stack, Alert, AlertTitle, Collapse } from '@mui/material';

function FormActions({ context, client = 'lexisnexis', formType }) {
  const [showActions, setShowActions] = useState(false);

  const handlePrefill = ({ firstName, lastName }) => {
    context.resetForm();
    if (formType === 'identity') {
      context.setValues({
        firstName: firstName,
        middleInitials: 'M',
        lastName: lastName,
        address: '20 Main st.',
        city: 'Little Rock',
        state: 'AR',
        zipCode: '72203',
        email: 'myemail@abcd.com',
        phoneNumber: '9292945555',
      });
    } else {
      context.setValues({
        business: {
          name: 'ABCD Corp.',
          address: '1 L st.',
          city: 'Little Rock',
          state: 'AR',
          zipCode: '72203',
          ITINorFEIN: '123456789',
          email: 'myemail@abcd.com',
          phoneNumber: '9292945555',
        },
        representative: {
          firstName: firstName,
          middleInitials: 'M',
          lastName: lastName,
          address: '20 Groove st.',
          city: 'Little Rock',
          state: 'AR',
          zipCode: '72203',
          dateOfBirth: new Date(),
          email: 'myemail@abcd.com',
          phoneNumber: '9292945555',
        },
      });
    }
  };

  const commonButtonProps = {
    size: 'small',
    variant: 'outlined',
    disabled: context.isSubmitting,
  };

  return (
    <Alert severity='info' sx={{ background: formType === 'identity' ? 'transparent' : '' }}>
      <AlertTitle sx={{ cursor: 'pointer' }} onClick={() => setShowActions(prev => !prev)}>
        Form Actions
      </AlertTitle>
      <Collapse in={showActions}>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 1, md: 2 }}>
          <Button
            {...commonButtonProps}
            color='success'
            onClick={() => handlePrefill({ firstName: 'Susan', lastName: 'Sample' })}
          >
            Susan Sample (success path)
          </Button>
          <Button
            {...commonButtonProps}
            color='warning'
            onClick={() => handlePrefill({ firstName: 'Rick', lastName: 'Sanchez' })}
          >
            Rick Sanchez (reject path)
          </Button>

          <Button {...commonButtonProps} color='info' onClick={() => context.resetForm()}>
            Reset fields
          </Button>
        </Stack>
      </Collapse>
    </Alert>
  );
}

export default FormActions;
