export const ACUANT_INITIALIZING = 'ACUANT_INITIALIZING';
export const ACUANT_SUCCESS = 'ACUANT_SUCCESS';
export const ACUANT_FAILURE = 'ACUANT_FAILURE';

const initialState = {
  initializing: false,
  success: false,
  error: '',
};

const acuantSdkReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ACUANT_INITIALIZING:
      return { ...state, initializing: true, error: '', success: false };
    case ACUANT_SUCCESS:
      return { ...state, initializing: false, success: true };
    case ACUANT_FAILURE:
      return { ...state, initializing: false, error: payload };
    default:
      return state;
  }
};

export default acuantSdkReducer;
