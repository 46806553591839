import React, { useEffect, useState } from 'react';
import { Link, Paper, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import Notification from '../common/Notification/Notification';
import RequestSummary from '../common/RequestSummary/RequestSummary';

const ErrorPage = ({ id, error, DDP, resetState = () => {} }) => {
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    setShowNotification(true); // will trigger animation on notification block
  }, []);

  return (
    <>
      <Notification severity={'error'} notificationText={error} showNotification={showNotification} />
      <Paper elevation={2} sx={{ px: 2, py: 4 }}>
        <Typography variant='h4' textAlign={'center'}>
          The next step will involve automated step-up authentication
        </Typography>

        {/* // TODO: DDP FOR DEMO, REMOVE IT LATER */}
        <RequestSummary summary={DDP} title='DDP Response' show={showNotification} />
        <Link component={RouterLink} to={`/${id}`} underline='always' onClick={resetState}>
          Go to Home Page
        </Link>
      </Paper>
    </>
  );
};

export default ErrorPage;
