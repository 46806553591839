/* eslint-disable no-unused-vars */
import React from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import { useField } from 'formik';
import { IMaskInput } from 'react-imask';

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, definitions, mask, ...other } = props;

  const handleChange = value => onChange({ target: { name: props.name, value } });

  return (
    <IMaskInput
      {...other}
      mask={mask}
      unmask={true}
      definitions={definitions}
      inputRef={ref}
      onAccept={handleChange}
      overwrite
    />
  );
});

const MaskfieldWrapper = ({ hide, name, mask, definitions, ...otherProps }) => {
  const [hideField, setHideField] = React.useState(true);
  const [field, meta] = useField(name);

  const handlehideField = () => {
    setHideField(!hideField);
  };

  const configTextfield = {
    ...field,
    ...otherProps,
    fullWidth: true,
    variant: 'outlined',
  };

  if (meta && meta.touched && meta.error) {
    configTextfield.error = true;
    configTextfield.helperText = meta.error;
  }

  const adornment = hide ? (
    <InputAdornment position='start'>
      <IconButton aria-label='toggle social security number visibility' onClick={handlehideField}>
        {hideField ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </InputAdornment>
  ) : null;

  // configTextfield.type = hide && hideField ? 'password' : 'text';

  return (
    <TextField
      {...configTextfield}
      InputProps={{
        // endAdornment: adornment,
        inputComponent: TextMaskCustom,
        inputProps: {
          mask,
          definitions,
        },
      }}
    />
  );
};

export default MaskfieldWrapper;
