import { Box, Container } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import { ConfettiProvider } from '../../../context/ConfettiContext';

import customThemes from './themes';

function Layout({ children }) {
  const location = useLocation();

  let theme = customThemes['default'];

  const customer = location.pathname.split('/')[1];

  if (customer && customThemes[customer]) {
    theme = customThemes[customer];
  } else {
    theme = customThemes['default'];
  }

  return (
    <ConfettiProvider>
      <ThemeProvider theme={createTheme({ ...theme })}>
        <Box
          bgcolor='background.default'
          sx={{
            backgroundImage: theme?.backgroundImage,
            backgroundSize: 'cover',
          }}
          minHeight='100vh'
        >
          <Container maxWidth='md'>
            <main>{children}</main>
          </Container>
        </Box>
      </ThemeProvider>
    </ConfettiProvider>
  );
}

export default Layout;
