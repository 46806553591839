import { Box, Card, CardActionArea, Typography } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import HeaderBar from '../common/HeaderBar/HeaderBar';

import ONE_VET_LOGO from '../common/Layout/logos/onevet_logo.png';
import UTS_LOGO from '../common/Layout/logos/uts_logo.png';

const VerifiersOptions = () => {
  const verifiers = [
    {
      img: ONE_VET_LOGO,
      background: '#0575ba',
      title: 'OneVet Logo',
      path: '/onevet/verifier',
    },
    {
      img: UTS_LOGO,
      background: '#0c529c',
      title: 'Ukraine Take Shelter Logo',
      path: '/uts/verifier',
    },
  ];

  return (
    <>
      <HeaderBar name='lexisnexis' />
      <Link component={RouterLink} to={'/'} replace underline='always'>
        Go back
      </Link>
      <Typography gutterBottom variant='h5' align='center' component='h1'>
        Choose the verifier :
      </Typography>
      <Box display='flex' mt={4} justifyContent='center' gap={4} flexWrap='wrap'>
        {verifiers.map(verifier => {
          return (
            <Card
              key={verifier.path}
              raised
              sx={{
                bgcolor: verifier.background,
                width: '250px',
                height: '80px',
              }}
            >
              <CardActionArea
                component={RouterLink}
                to={verifier.path}
                sx={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Box
                  component='img'
                  alt={verifier.title}
                  sx={{
                    maxHeight: '100px',
                    maxWidth: '133px',
                  }}
                  src={verifier.img}
                />
              </CardActionArea>
            </Card>
          );
        })}
      </Box>
    </>
  );
};

export default VerifiersOptions;
