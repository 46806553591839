import { combineReducers } from 'redux';
import acuantSdkReducer from './acuant/acuantReducer';
import vcReducer from './vcStore/vcReducer';

const rootReducer = combineReducers({
  acuantSdk: acuantSdkReducer,
  vcStore: vcReducer,
});

export default rootReducer;
