/* eslint-disable react-hooks/exhaustive-deps */
import DatePicker from '../DatePicker/DatePicker';
import TextField from '../TextField/TextField';
import Select from '../Select/Select';

import { useSelector } from 'react-redux';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { statesList } from '../../../utils/statesList';

import LoadingButton from '@mui/lab/LoadingButton';
import Grid from '@mui/material/Grid';
import Maskfield from '../TextField/MaskedTextField';
import { Typography } from '@mui/material';
import FormActions from '../FormActions/FormActions';
import LoadingOverlay from '../LoadingOverlay/LoadingOverlay';
import { useEffect, useRef } from 'react';

const initialFields = {
  business: {
    name: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    ITINorFEIN: '',
    email: '',
    phoneNumber: '',
  },
  representative: {
    firstName: '',
    middleInitials: '',
    lastName: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    dateOfBirth: null,
    email: '',
    phoneNumber: '',
  },
};

const validation = Yup.object().shape({
  business: Yup.object().shape({
    name: Yup.string().required('Please provide legal business name.'),
    address: Yup.string().required('Please provide bussiness address.'),
    city: Yup.string().required('Please provide bussiness city.'),
    state: Yup.string().required('Please provide bussiness state.'),
    zipCode: Yup.string()
      .matches(/^[0-9]+$/, 'Must be digits only')
      .min(5, 'Minimum 5 digits')
      .max(5, 'Maximum 5 digits')
      .required('Please provide bussiness zip code.'),
    ITINorFEIN: Yup.string()
      .matches(/^[0-9]+$/, 'Must be digits only')
      .min(9, 'Minimum 9 digits')
      .max(9, 'Maximum 9 digits')
      .required('Please provide bussiness FEIN or ITIN Number.'),
    email: Yup.string().email('Invalid email.').required('Please provide bussiness email address.'),
    phoneNumber: Yup.string()
      .matches(/^[0-9]+$/, 'Please provide a valid phone number.')
      .min(10, 'Phone number must be at least 10 digits.')
      .max(10, 'Phone number must be no more than 10 digits.')
      .required('Please provide bussiness telephone number.'),
  }),
  representative: Yup.object().shape({
    firstName: Yup.string()
      .matches(/^[A-Za-z ]*$/, 'The last name should contain only letters')
      .required('Please provide your first name.'),
    middleInitials: Yup.string().matches(/^[A-Za-z ]*$/, 'The middle name should contain only letters'),
    lastName: Yup.string()
      .matches(/^[A-Za-z ]*$/, 'The last name should contain only letters')
      .required('Please provide your last name.'),
    address: Yup.string().required('Please provide your address.'),
    city: Yup.string().required('Please provide a city.'),
    state: Yup.string().required('Please provide a state.'),
    zipCode: Yup.string()
      .matches(/^[0-9]+$/, 'Must be digits only')
      .min(5, 'Minimum 5 digits')
      .max(5, 'Maximum 5 digits')
      .required('Please enter a valid zipcode.'),
    dateOfBirth: Yup.date()
      .nullable()
      .typeError('Please provide a valid date.')
      .required('Please provide your date of birth.'),
    email: Yup.string().email('Invalid email.').required('Please provide your email.'),
    phoneNumber: Yup.string()
      .matches(/^[0-9]+$/, 'Please provide a valid phone number.')
      .min(10, 'Phone number must be at least 10 digits.')
      .max(10, 'Phone number must be no more than 10 digits.')
      .required('Please provide your phone number.'),
  }),
});

function BusinessRepForm({ handleSubmit, loading, client }) {
  const form = useRef(null);

  const formState = useSelector(state => state.vcStore.form);

  useEffect(() => {
    if (formState?.firstName) {
      form.current.setValues(formState);
    }
  }, []);

  return (
    <Formik
      innerRef={form}
      initialValues={initialFields}
      validationSchema={validation}
      onSubmit={handleSubmit}
    >
      {context => (
        <Form noValidate autoComplete='off'>
          <Grid container alignItems='flex-start' spacing={5}>
            <Grid container spacing={2} item xs={12} md={6}>
              <Grid item xs={12}>
                <Typography variant='subtitle1'>Business Information:</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField size='small' name='business.name' label='Legal Business Name *' />
              </Grid>
              <Grid item xs={12}>
                <TextField size='small' name='business.address' label='Address *' />
              </Grid>
              <Grid item xs={12}>
                <TextField size='small' name='business.city' label='City *' />
              </Grid>
              <Grid item xs={12}>
                <Select size='small' name='business.state' label='State *' options={statesList} />
              </Grid>
              <Grid item xs={12}>
                <Maskfield
                  size='small'
                  name='business.zipCode'
                  label='Zip Code *'
                  mask='#0000'
                  definitions={{
                    '#': /[1-9]/,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField size='small' name='business.ITINorFEIN' label='Business FEIN or ITIN Number *' />
              </Grid>
              <Grid item xs={12}>
                <TextField size='small' type='email' name='business.email' label='E-mail Address *' />
              </Grid>
              <Grid item xs={12}>
                <Maskfield
                  size='small'
                  type='tel'
                  name='business.phoneNumber'
                  label='Telephone Number *'
                  mask='(#00) 000-0000'
                  definitions={{
                    '#': /[1-9]/,
                  }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} item xs={12} md={6}>
              <Grid item xs={12}>
                <Typography variant='subtitle1'>Business Representative:</Typography>
              </Grid>
              <Grid item xs={12} md={7}>
                <TextField size='small' name='representative.firstName' label='First Name *' />
              </Grid>
              <Grid item xs={12} md={5}>
                <TextField size='small' name='representative.middleInitials' label='Middle Initial' />
              </Grid>
              <Grid item xs={12}>
                <TextField size='small' name='representative.lastName' label='Last Name *' />
              </Grid>
              <Grid item xs={12}>
                <DatePicker
                  size='small'
                  type='date'
                  name='representative.dateOfBirth'
                  label='Date of Birth *'
                />
              </Grid>
              <Grid item xs={12}>
                <TextField size='small' name='representative.address' label='Address *' />
              </Grid>
              <Grid item xs={12}>
                <TextField size='small' name='representative.city' label='City *' />
              </Grid>
              <Grid item xs={12} md={6}>
                <Select size='small' name='representative.state' label='State *' options={statesList} />
              </Grid>
              <Grid item xs={12} md={6}>
                <Maskfield
                  size='small'
                  name='representative.zipCode'
                  label='Zip Code *'
                  mask='#0000'
                  definitions={{
                    '#': /[1-9]/,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField size='small' type='email' name='representative.email' label='E-mail Address *' />
              </Grid>
              <Grid item xs={12}>
                <Maskfield
                  size='small'
                  type='tel'
                  name='representative.phoneNumber'
                  label='Telephone Number *'
                  mask='(#00) 000-0000'
                  definitions={{
                    '#': /[1-9]/,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <LoadingButton
            loading={loading}
            fullWidth
            sx={{ maxWidth: '500px', display: 'block', margin: '20px auto' }}
            size='large'
            variant='contained'
            type='submit'
          >
            Submit
          </LoadingButton>
          <LoadingOverlay loading={loading} />

          {/* FormActions is for demo, it has buttons for pre populating input fields */}
          <FormActions context={context} formType='bussiness' client={client} />
        </Form>
      )}
    </Formik>
  );
}

export default BusinessRepForm;
