import axios from 'axios';

const instance = axios.create();

// instance.defaults.baseURL = process.env.REACT_APP_API_URL;
// instance.defaults.headers.common['Authorization'] = token;
// instance.interceptors.request.use(req=>{})

const handleAxiosError = error => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    return {
      data: error.response.data,
      status: error.response.status,
      headers: error.response.headers,
      message: error.response.data.message || error.response.statusText,
    };
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    return { ...error.request, message: 'Request error' };
  } else {
    // Something happened in setting up the request that triggered an Error
    return { message: error.message };
  }
};

const http = {
  get: instance.get,
  post: instance.post,
  put: instance.put,
  delete: instance.delete,
  handleAxiosError,
};

export default http;
