import { CircularProgress } from '@mui/material';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import QRCode from '../common/QRCode/QRCode';
import http from '../../services/http';
import RequestSummary from '../common/RequestSummary/RequestSummary';
import Notification from '../common/Notification/Notification';
import { useConfetti } from '../../context/ConfettiContext';

const VerifierPage = ({ id }) => {
  const [qrCodeData, setQrCodeData] = useState({
    showNotification: false,
    isLoading: false,
    error: null,
    data: null,
  });

  const [scanned, setScanned] = useState({
    request_retrieved: false,
    presentation_verified: false,
    message: '',
  });

  const shootConfetti = useConfetti();

  useEffect(() => {
    (async () => {
      try {
        setQrCodeData(prev => ({ ...prev, showNotification: true, isLoading: true }));
        const { data } = await http.get(`/api/verifier/presentation-request?id=${id}`);
        console.log(`respond`, data);
        setQrCodeData(prev => ({ ...prev, data, isLoading: false }));
      } catch (error) {
        const axiosError = http.handleAxiosError(error);
        console.log(`axiosError`, axiosError);
        setQrCodeData(prev => ({ ...prev, error: axiosError.message, isLoading: false }));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let checkStatus;
    if (qrCodeData.data?.id) {
      checkStatus = setInterval(async () => {
        try {
          const { data: response } = await http.get(
            '/api/verifier/presentation-response?id=' + qrCodeData.data.id,
          );
          if (response.status) {
            if (response.status === 'request_retrieved') {
              setScanned(prev => ({ ...prev, request_retrieved: true, message: response.message }));
            }

            if (response.status === 'presentation_verified') {
              setScanned(prev => ({
                ...prev,
                presentation_verified: true,
                message: response.message,
                payload: response,
              }));
              shootConfetti();
              clearInterval(checkStatus);
            }
          }
        } catch (error) {
          const axiosError = http.handleAxiosError(error);
          console.log(`axiosError`, axiosError);
          setQrCodeData(prev => ({ ...prev, error: axiosError.message, isLoading: false }));
          clearInterval(checkStatus);
        }
      }, 3000);
    }
    return () => clearInterval(checkStatus);
  }, [qrCodeData.data?.id]);

  const getNotificationText = () => {
    if (qrCodeData.error) return qrCodeData.error;
    if (scanned.request_retrieved) return scanned.message;
    return 'Scan with Microsoft Authenticator';
  };

  if (qrCodeData.isLoading) return <CircularProgress sx={{ display: 'block', margin: 'auto' }} />;
  // #0c529c

  const getPath = () => {
    if (id === 'uts') return '/verifiersoptions';
    return `/${id}`;
  };

  return (
    <>
      <Notification
        severity={qrCodeData.error ? 'error' : 'success'}
        notificationText={getNotificationText()}
        showNotification={qrCodeData.showNotification}
      />
      <Link component={RouterLink} to={getPath()} replace underline='always'>
        {scanned.presentation_verified ? ' Go to Home Page' : 'Cancel'}
      </Link>
      {/* // TODO: DDP FOR DEMO, REMOVE IT LATER */}
      <RequestSummary summary={scanned.payload} title='Azure Response' show={scanned.payload} />
      {qrCodeData.data?.url && !scanned.request_retrieved && !qrCodeData.error && (
        <QRCode value={qrCodeData.data.url} costumerId={id} />
      )}
    </>
  );
};

export default VerifierPage;
