import { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import NotFoundPage from './components/NotFoundPage/NotFoundPage';

import Layout from './components/common/Layout/Layout';
import CssBaseline from '@mui/material/CssBaseline';

import { useDispatch, useSelector } from 'react-redux';
import { acuantFailure, acuantInit, acuantSuccess } from './redux/acuant/acuantActions';

import http from './services/http';

import VerifiersOptions from './components/VerifiersOptions/VerifiersOptions';
import Notification from './components/common/Notification/Notification';

import LexisNexis from './components/LexisNexis/LexisNexis';
import HomePage from './components/HomePage/HomePage';
import FPJourne from './components/FPJourne/FPJourne';
import OneVet from './components/OneVet/OneVet';
import PCB from './components/PCB/PCB';
import ACME from './components/ACME/ACME';
import UTS from './components/UTS/UTS';
import BestBuy from './components/BestBuy/BestBuy';

function App() {
  const dispatch = useDispatch();

  const acuant = useSelector(state => state.acuantSdk);

  useEffect(() => {
    loadAcuant();
    // eslint-disable-next-line no-undef
    return () => window.AcuantJavascriptWebSdk?.endWorkers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadAcuant = async () => {
    try {
      const response = await http.get('/api/acuant');

      const cameraSdkDir = '/webSdk';
      window.acuantConfig = { path: process.env.PUBLIC_URL + cameraSdkDir };
      window.onAcuantSdkLoaded = () => initializeAcuant(response.data.key);

      const createPath = filePath => process.env.PUBLIC_URL + cameraSdkDir + filePath;

      const scripts = [
        { src: createPath('/AcuantJavascriptWebSdk.min.js'), onload: () => window.loadAcuantSdk() },
        { src: createPath('/AcuantPassiveLiveness.min.js'), async: true },
        { src: createPath('/AcuantCamera.min.js'), async: true },
        { src: createPath('/opencv.min.js'), async: true },
      ];

      const appendScriptToBody = attributes => {
        let el = document.createElement('script');
        for (const key in attributes) el[key] = attributes[key];
        document.body.appendChild(el);
      };

      scripts.forEach(appendScriptToBody);
    } catch (error) {
      console.log('error', error);
      dispatch(acuantFailure({ error: error.message }));
    }
  };

  const initializeAcuant = key => {
    dispatch(acuantInit());

    // eslint-disable-next-line no-undef
    window.AcuantJavascriptWebSdk = AcuantJavascriptWebSdk;
    const Acuant = window.AcuantJavascriptWebSdk;

    const sdkConfig = {
      workers: [Acuant.ACUANT_IMAGE_WORKER, Acuant.ACUANT_METRICS_WORKER], //possible list shown below, defaults to all workers
      creds: btoa(key), //Acuant credentials in base64 (basic auth format id:pass)
      url: 'https://us.acas.acuant.net', //endpoint for Acuant's ACAS server
      cdn: 0, //set to 1 if hosting via cdn, defaults to 0
    };

    const onSuccess = () => {
      console.log('----------Acuant Success--------------------');

      const callback = () => {
        console.log('Worker started');
        // Appending ref to window to avoid many code changes
        // eslint-disable-next-line no-undef
        window.AcuantPassiveLiveness = AcuantPassiveLiveness;
        // eslint-disable-next-line no-undef
        window.AcuantCameraUI = AcuantCameraUI;
        // eslint-disable-next-line no-undef
        window.AcuantCamera = AcuantCamera;
      };

      Acuant.startWorkers(callback, sdkConfig.workers, sdkConfig.cdn);
      dispatch(acuantSuccess());
    };

    const onFail = (code, description) => {
      console.log('----------Acuant Failed--------------------');
      console.dir({ code, description });
      dispatch(acuantFailure({ error: description }));
    };

    sdkConfig.callbacks = { onSuccess, onFail };

    Acuant.initialize(sdkConfig.creds, sdkConfig.url, sdkConfig.callbacks);
  };

  return (
    <>
      <CssBaseline />
      <Router>
        <Layout>
          <Notification
            severity={'warning'}
            notificationText={acuant.error}
            showNotification={!!acuant.error}
          />
          <Switch>
            <Route path='/verifiersoptions'>
              <VerifiersOptions />
            </Route>

            <Route path='/uts/verifier'>
              <UTS />
            </Route>

            <Route path='/lexisnexis'>
              <LexisNexis />
            </Route>

            <Route path='/onevet'>
              <OneVet />
            </Route>

            <Route path='/fpjourne'>
              <FPJourne />
            </Route>

            <Route path='/pcb'>
              <PCB />
            </Route>

            <Route path='/acme'>
              <ACME />
            </Route>

            <Route path='/bestbuy'>
              <BestBuy />
            </Route>

            <Route path='/'>
              <HomePage />
            </Route>

            <Route path='*'>
              <NotFoundPage />
            </Route>
          </Switch>
        </Layout>
      </Router>
    </>
  );
}

export default App;
